// App main
const site = async () => {
    // Async load the vue module
    const [ Vue, VueSlickCarousel ] = await Promise.all([
        import(/* webpackChunkName: "vue" */ 'vue'),
        import(/* webpackChunkName: "vue-slick-carousel" */ 'vue-slick-carousel'),
    ]).then(arr => arr.map(({ default: defaults }) => defaults));

    const vm = new Vue({

        el: '#page-container',
        components: {
            VueSlickCarousel,
        },

        data: () => ({
            
            slickOptions: {

                infinite: true,
                slidesToShow: 1,
                arrows: false,
                dots: true,

            },
            
            slickEditionsOptions: {
                dots: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
                autoplay: false,
                infinite: false,
                responsive: [

                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },

                ]

            },

            slickInstagramOptions: {
                dots: false,
                slidesToShow: 3,
                slidesToScroll: 2,
                arrows: true,
                autoplay: false,
                infinite: false,
                responsive: [

                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },

                ]

            },

            menuOpen: false,

        }),

        methods: {

            toggleMenu() {
                this.menuOpen = !this.menuOpen;
            }

        },
    })
};

// Execute async function
site().then( (value) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}